window.jQuery = window.$ = require('jquery');

require('bootstrap');
colorbox = require('jquery-colorbox');

$(document).ready(function(){
	function toggleNav(){
	  var chkbox = document.getElementById("mobile-toggle");
	  chkbox.checked = !chkbox.checked;
	  //console.log('rest');
	}
	var nav = document.getElementById('main-nav');
	nav.addEventListener('click', toggleNav);


	// $('a.display-image').each(function(){
	// 	var rel = $(this).attr('rel');
	// 	$(this).colorbox({rel:rel,maxWidth:'95%',maxHeight:'95%'});
	// });
});
